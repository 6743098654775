<!--
 * @Author: gjm
 * @Date: 2021-04-15 13:18:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-02 17:37:18
 * @Description: 在线咨询-创建问题
-->
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 12px !important;
}
.source-target {
  text-align: center;
  font-size: 20px;
}
</style>

<template>
  <a-modal
    :visible="questionVisible"
    class="create-question"
    :width="576"
    title="创建问题"
    @cancel="handleCancel"
    okText="提交"
    @ok="handleOk"
  >
    <div class="question-box">
      <a-form-model ref="question" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
        <a-form-model-item label="问题内容" required prop="questionContext">
          <a-textarea
            v-model="form.questionContext"
            placeholder="请输入您的问题......"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-model-item>

        <a-form-model-item label="语言对">
          <a-row>
            <a-col :span="11">
              <a-form-model-item
                :validate-status="
                  getValidateStatus(
                    form.sourceCode,
                    form.targetCodeList ? form.targetCodeList.join(',') : '',
                    form.sourceCode
                  )
                "
                :help="
                  getValidateTip(
                    form.sourceCode,
                    form.targetCodeList ? form.targetCodeList.join(',') : '',
                    form.sourceCode
                  )
                "
              >
                <a-select
                  allowClear
                  show-search
                  :filter-option="langFilterOption"
                  v-model="form.sourceCode"
                  :placeholder="$t('orderInfo.source')"
                >
                  <a-select-option v-for="(item, index) in sourceCodeList" :key="index" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <div class="source-target">></div>
            </a-col>
            <a-col :span="11">
              <a-form-model-item
                :validate-status="
                  getValidateStatus(
                    form.sourceCode,
                    form.targetCodeList ? form.targetCodeList.join(',') : '',
                    form.targetCodeList ? form.targetCodeList.join(',') : ''
                  )
                "
                :help="
                  getValidateTip(
                    form.sourceCode,
                    form.targetCodeList ? form.targetCodeList.join(',') : '',
                    form.targetCodeList ? form.targetCodeList.join(',') : ''
                  )
                "
              >
                <a-select
                  allowClear
                  show-search
                  :filter-option="langFilterOption"
                  v-model="form.targetCodeList"
                  :placeholder="$t('orderInfo.target')"
                  mode="multiple"
                >
                  <a-select-option v-for="(item, index) in targetCodeList" :key="index" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>

        <a-form-model-item label="附件">
          <NeoUploadButton
            title="上传附件"
            v-model="form.attachmentFile"
            :showNote="form.attachmentFile.length > 0 ? false : true"
            :showFileList="true"
            :showIcon="true"
            accept=".doc, .docx, .xlsx, .xls, .pdf, .png, .jpg, .zip"
            :maxSize="10"
            :maxLength="1"
            note="支持doc、docx、xlsx、xls、pdf、png、jpg、zip格式，且大小不超过10M"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import debounce from 'lodash/debounce'

const { validate, acceptMap } = window.$g

export default {
  name: 'CreateQuestionModal',
  components: {
    NeoUploadButton,
  },
  props: {
    questionVisible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      acceptMap,
      uploading: false,
      form: {
        questionContext: undefined,
        attachmentFile: [],
        sourceCode: undefined,
        targetCodeList: undefined,
      },
      rules: {
        questionContext: [validate.required, validate.size_500],
      },
      sourceCodeList: [],
      targetCodeList: [],
    }
  },
  computed: {
    ...mapState('app', {
      langList: (state) => state.langList,
    }),
  },
  watch: {
    questionVisible(v) {
      if (v) {
        console.log('this.info :>> ', this.info)
        this.form.sourceCode = this.info.sourceCode
        this.form.targetCodeList = this.info.targetCodeList?.split(',')
        this.sourceCodeList = this.langList.filter((_) => _.code == this.info.sourceCode)
        this.targetCodeList = this.langList.filter((_) => this.info.targetCodeList?.split(',').includes(_.code))
      } else {
        this.form.questionContext = undefined
        this.form.attachmentFile = []
        this.$refs.question.clearValidate()
      }
    },
  },
  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getValidateStatus(sourceCode, targetCode, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    getValidateTip(sourceCode, targetCode, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return this.$t('validate.required')
      } else {
        return ''
      }
    },

    handleCancel() {
      this.$emit('closeQuestionModal', false)
    },
    handleOk: debounce(function () {
      try {
        const params = {
          orderId: this.info.id,
          pid: 0,
          questionContext: this.form.questionContext,
          sourceCode: this.form.sourceCode,
          targetCode: this.form.targetCodeList?.join(','),
          attachmentFile: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].url : null,
          attachmentName: this.form.attachmentFile.length > 0 ? this.form.attachmentFile[0].name : null,
          questionerType: this.$g.isCust ? 1 : 2,
          questionType: 1,
        }
        this.$refs.question.validate(async (valid) => {
          if (
            valid &&
            ((this.form.sourceCode && this.form.targetCodeList?.join(',')) ||
              (!this.form.sourceCode && !this.form.targetCodeList?.join(',')))
          ) {
            await this.$http('createQuestion', params)
              .then(() => {
                this.$emit('confirm')
                this.$emit('closeQuestionModal', false)
              })
              .catch((err) => {
                this.$httpNotify(err)
              })
          }
        })
      } catch (error) {
        this.$httpNotify(error)
      }
    }, 1000),
  },
}
</script>
